.aiEnabled{
    color: green;
}

.trackOverviewWrapper{
    display:flex;
    flex-direction: column;
    padding: 10px 0;
}
.trackName{
    white-space:nowrap;
}
.trackName button{
    border: 0;
    padding: 0;
    background: transparent;
    font-size: var(--fontSizeBody);
}
.trackName button:hover {
    cursor: pointer;
}
