.aiEnabled{
    color: green;
}
.seriesOverviewWrapper{
    display:flex;
    flex-direction: column;
    padding: 10px 0;
}
.seriesName button{
    border: 0;
    padding: 0;
    background: transparent;
    font-size: var(--fontSizeBody);
}
.seriesName button:hover {
    cursor: pointer;
}