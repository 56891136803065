.seasonOverviewWrapper{
    display:grid;
    grid-template-columns: 80px 1fr 50px 50px 50px 1fr 110px;
    column-gap: 20px;
    border-bottom:1px solid #666666;
    padding-bottom:20px;
    margin-bottom:20px;
}
.seasonName{
    white-space:nowrap;
}
.seasonVehicle{
    white-space:nowrap;
}

.seasonDownload a{
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    background-color: #000000;
    color:#ffffff;
    text-decoration: none;
}
