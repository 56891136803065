.headerWrapper {
    width: var(--contentWidth);
    margin:0 auto; 
    padding:20px 0;
}
.headerWrapper h1{
    margin:0;
    
}
.headerContent{
    display: flex;
    align-items:center;
    justify-content:space-between;

}
.headerWrapper a {
    text-decoration: none;
    color: #000000;
    font-size: 20px;
    padding: 14px 25px;
    border-right: 1px solid #000000;
}
.headerWrapper a:last-child{
    border-right:none;
}

.headerWrapper a:hover {
    background-color: #000000;
    color: #ffffff;
}