.aiEnabled{
    color: green;
}
.dataRow{
    padding:20px 5px;
    border-bottom:1px solid #666666;
    display:flex;
    justify-content:space-between;
}
.carFilters {
    margin-bottom: var(--section-vertical-spacing);
}
.carFilters button{
    padding:4px 8px;
    margin-right:20px;
}
.legendWrapper {
    margin-bottom: var(--section-vertical-spacing);
}
.legendItem {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}
.legendColor {
    width: 25px;
    height: 25px;
    background-color: green;
    border:1px solid #000;
    
}
.legendLabel {

}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.row:nth-child(even) {
    background-color: #f3f3f3;
}
