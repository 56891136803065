/* Page Layout elements */

.introWrapper{
    background-color:#000000;
    color:#ffffff;
}
.pageIntroContent{
    width: var(--contentWidth);
    margin:0 auto;  
    padding:50px 0;
}
.pageContent{
    width: var(--contentWidth);
    margin:0 auto; 
    padding:20px 0;
}
