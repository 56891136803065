/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Lato:wght@400;700&display=swap');
/* END Fonts */


/* Normalize */
html,
body{
    margin:0;
    padding:0;
}
* {
    padding: 0;
    margin: 0;
}
/* END Normalize */

/* CSS Custom Properties */
:root {

    /***** Fonts *****/
    --fontFamilyBody: 'Lato', sans-serif;
    --fontFamilyHeading: 'Josefin Sans', sans-serif;
    --fontSizeBody: 18px;
    --fontSizeH1: 48px;
    --fontSizeH2: 36px;
    --fontSizeH3: 28px;
    --fontSizeH4: 20px;

    /***** Sizing *****/
    --contentWidth:75%;

    /***** Spacing *****/
    --section-vertical-spacing: 40px;

}
/* END CSS Custom Properties */

/* Overall Styles */
html,
body,
#root{
    width:100%;
    height:100%;
    font-family:var(--fontFamilyBody);
    font-size: var(--fontSizeBody);
    line-height:1.5;
}
/* END Overall Styles */

/* Application Layout */
#app{
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;  
}
/* END application layout */

/* Common elements */
h1 {
    font-family:var(--fontFamilyHeading);
    font-size: var(--fontSizeH1);
    font-weight:700;
    line-height:1;
}
h2{
    font-family:var(--fontFamilyHeading);
    font-size: var(--fontSizeH2);
    font-weight:normal;
}
h3{
    font-family:var(--fontFamilyHeading);
    font-size: var(--fontSizeH3);
    font-weight:normal;
}
h4{
    font-family:var(--fontFamilyHeading);
    font-size: var(--fontSizeH4);
    font-weight:normal;
}
button{
    padding: 5px 10px;
}
/* END common elements */
